<template>
  <div class="skypes">
    <h1>Просветительские беседы</h1>
    <div class="years" v-if="getAllYears">
      <div class="year" 
          v-for="year in getAllYears" 
          :key="year" 
          :class="{'selected': year == selectYear}"
          @click="clickYear(year)">
          {{ year }}
      </div>
    </div>
    <div class="btn__wrap" v-if="filterSelectedYearLinks">
        <a class="btn btn-block" :href="talk.url" target="_blank" v-for="talk in filterSelectedYearLinks" :key="'talk-' + talk.id">{{ talk.name }}</a>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";

export default {
  name: "Links",
  data() {
    return {
      selectYear: new Date().getFullYear().toString()
    }
  },
  created() {
    document.title = "Просветительские беседы";
    this.$store.dispatch('getTalks');
  },
  computed: {
    ...mapState({
      talks: state => state.talks
    }),
    filterSelectedYearLinks () {
        return this.filtedLinks(this.selectYear)
    },
    ...mapGetters([
      'filtedLinks',
      'getAllYears',
    ])
  },
  methods: {
    clickYear(selectYear) {
      this.selectYear = selectYear
    }
  }
}
</script>

<style scoped lang="less">
@import "../assets/vars";

.skypes {
  .years {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 20px;

    .year {
      cursor: pointer;

      &.selected {
        border-bottom: 1px solid @color-h;
      }
    }
  }
  .btn {
    display: block;
    background-color: @color-h;
    color: @color-l;
    text-align: center;
    text-decoration: none;
    font-size: 1.5rem;

    &.btn-big {
      padding: 1rem;
      margin-bottom: 1rem;
      font-size: 2rem;
    }

    &.btn-block {
      padding: .5rem;
      margin-bottom: 1rem;
    }
  }
}
</style>
